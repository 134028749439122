import * as React from 'react';
import type { PageProps } from 'gatsby';
import { LocationState } from '../typings';
import BrowseLayout from '../layouts/browse';

interface ExtendedPageProps extends Omit<PageProps, 'location'> {
  location: {
    host: string;
    hostname: string;
    href: string;
    port: string;
    protocol: string;
    pathname: string;
    search: string;
    hash: string;
    key?: string | undefined;
    state: LocationState;
  };
}

const BrowsePage = (data: ExtendedPageProps) => {
  const { location } = data;
  const { state } = location;
  const browseFilter = new URLSearchParams(location.search).get('filters') || '';
  return <BrowseLayout filterQuery={browseFilter} locationState={state} />;
};

export default BrowsePage;
