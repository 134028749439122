import * as React from 'react';
import { Container, Grid, Typography, Paper, IconButton } from '@material-ui/core';
import { graphql, useStaticQuery } from 'gatsby';
import FilterListIcon from '@material-ui/icons/FilterList';
import { defaultLocation, Languages } from '../utils/getAllSupportedLanguages';
import FilterSortDialogMobile from '../components/FilterSortDialogMobile';
import SearchResultTable from '../components/SearchResultTable';
import Seo from '../components/Seo';
import FilterSortFields from '../components/FilterSortFields';
import { FilterSort, LocationLanguageContextType, BrowseQueryProps } from '../typings';
import { filterTemplates } from '../utils/filterTemplates';
import { sortTemplates } from '../utils/sortTemplates';
import {
  getTemplateCategoryByLocale,
  getCategoriesWithTemplates,
  setLanguageFilterByGeo,
  getDefaultFilters
} from '../utils/getFilterOptions';
import { LocationLanguageContext } from '../contexts/LocationLanguageContext';
import { matchesMobile } from '../utils/mediaQuery';

const BrowseLayout: React.FC<BrowseQueryProps> = ({ filterQuery, locationState }) => {
  const data = useStaticQuery(
    graphql`
      query BrowseLayoutQuery {
        allStrapiBrowsePage {
          nodes {
            Title
            locale
          }
        }
        allStrapiTemplate(sort: { fields: Title }) {
          nodes {
            id
            published_at
            Title
            Description
            SeoContent
            Keywords
            Author
            Slug
            countries {
              name
              Localizations {
                locale
                localeName
                displayName
              }
            }
            categories {
              name
            }
            trades {
              name
            }
            template_language {
              name
            }
          }
        }
        allStrapiCategories(sort: { fields: rank, order: ASC }) {
          nodes {
            name
            rank
            Localizations {
              displayName
              locale
              localeName
            }
            templates {
              id
            }
          }
        }
        allStrapiTrades {
          nodes {
            name
            Localizations {
              displayName
              locale
              localeName
            }
            templates {
              id
            }
          }
        }
        allStrapiTemplateLanguage {
          nodes {
            Localizations {
              displayName
              locale
              localeName
            }
            name
            templates {
              id
            }
          }
        }
      }
    `
  );
  /** Set state for initial language filter. Default as 'English' */
  const [initialLangFilter, setInitialLangFilter] = React.useState<string[]>([Languages.English]);
  const initialFilterValues = getDefaultFilters();
  const getInitialFilters = () => {
    if (!filterQuery) return initialFilterValues;
    return {
      filters: {
        language: initialLangFilter ?? [],
        categories: filterQuery.split(':')[0] === 'category' ? [filterQuery.split(':')[1]] : [],
        trades: []
      },
      sort: 'titleasc'
    };
  };
  const initialFilterSortObj: FilterSort = getInitialFilters();

  const templates = data.allStrapiTemplate.nodes;
  const [templateResult, setTemplateResult] = React.useState(templates);
  const [filterSortValue, setFilterSortValue] = React.useState<FilterSort>(initialFilterSortObj);
  const [isOpenFilterSortDialog, setIsOpenFilterSortDialog] = React.useState(false);
  const locationLanguage = React.useContext<LocationLanguageContextType | null>(LocationLanguageContext);
  const language: string = locationLanguage?.language ?? Languages.English;
  const country = locationLanguage?.country ?? defaultLocation;

  const browsePageContent = data.allStrapiBrowsePage.nodes.find((x: any) => x.locale === language);

  // Redirect back to the browse page when 'browse' is clicked
  React.useEffect(() => {
    if (locationState) {
      const defaultFilters = getDefaultFilters(initialLangFilter);
      setFilterSortValue(defaultFilters);
    }
  }, [locationState]);

  const categories = getTemplateCategoryByLocale(getCategoriesWithTemplates(data.allStrapiCategories.nodes), language);
  const trades = getTemplateCategoryByLocale(getCategoriesWithTemplates(data.allStrapiTrades.nodes), language);
  const allTemplateLanguages = getTemplateCategoryByLocale(getCategoriesWithTemplates(data.allStrapiTemplateLanguage.nodes), language);

  // Trigger to update the language filter value when the country is changed.
  React.useEffect(() => {
    const countryLang = setLanguageFilterByGeo(country, allTemplateLanguages);
    setInitialLangFilter(countryLang);
    filterSortValue.filters.language = countryLang;
    setFilterSortValue(filterSortValue);
  }, [country]);

  React.useEffect(() => {
    let tempResult = filterTemplates(templates, filterSortValue.filters);
    tempResult = sortTemplates(tempResult, filterSortValue.sort);
    setTemplateResult([...tempResult]);
    return () => {
      setTemplateResult([]);
    };
  }, [filterSortValue.filters, filterSortValue.sort]);

  const openFilterSortDialog = () => {
    setIsOpenFilterSortDialog(true);
  };
  const handleFilterSortDialogClose = (value: any) => {
    if (value) {
      setFilterSortValue(value);
    }
    setIsOpenFilterSortDialog(false);
  };
  const handleFilterSortChange = (filteredValue: FilterSort) => {
    setFilterSortValue(filteredValue);
  };
  const isMobile = matchesMobile();
  if (browsePageContent) {
    return (
      <>
        <Seo title='Browse' isTemplatePage={false} />
        <Container>
          <br />
          <Grid container spacing={3}>
            {isMobile ? null : (
              <Grid item xs={12} sm={3}>
                <Paper style={{ padding: '1rem 1rem' }}>
                  <FilterSortFields
                    filterSortObj={filterSortValue}
                    onFilterSortChange={handleFilterSortChange}
                    isBrowsePage
                    languages={allTemplateLanguages}
                    categories={categories}
                    trades={trades}
                    locationState={locationState}
                  />
                </Paper>
              </Grid>
            )}
            <Grid item xs={12} sm={9}>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography style={{ marginBottom: '0', fontSize: '1.45rem', fontWeight: 400 }} variant='h1'>
                  {browsePageContent.Title}
                </Typography>
                {isMobile ? (
                  <IconButton aria-label='search' onClick={openFilterSortDialog}>
                    <FilterListIcon />
                  </IconButton>
                ) : null}
              </div>
              {templateResult ? (
                <div>
                  <SearchResultTable searchResults={templateResult} isMobile={isMobile} />
                </div>
              ) : null}
              <FilterSortDialogMobile
                filterSortObj={filterSortValue}
                isBrowsePage
                open={isOpenFilterSortDialog}
                onDialogClose={handleFilterSortDialogClose}
                languages={allTemplateLanguages}
                categories={categories}
                trades={trades}
                locationState={locationState}
              />
            </Grid>
          </Grid>
        </Container>
      </>
    );
  }

  return null;
};
export default BrowseLayout;
